<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
    <static-text />

    <div class="md:w-3/5 m-0 w-full justify-self-end">
      <custom-card title="Alteração de Senha">
        <h4>Olá {{ nome }}, tudo bem?</h4>
        <p style="padding-top:20px;padding-bottom:20px;">
          Preencha os campos abaixo e clique em atualizar senha.
        </p>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="refreshPassword()">
            <ValidationProvider
              v-if="Newe === true"
              name="Senha"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Senha"
                type="password"
                v-model="senha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <br />

            <ValidationProvider
              v-if="Newe === false"
              name="Senha"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Senha"
                type="password"
                v-model="senha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>

            <ValidationProvider
              v-if="Newe === true"
              name="Confirmação de Senha"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Confirmação de Senha"
                type="password"
                v-model="confirmacaoSenha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <br />

            <ValidationProvider
              v-if="Newe === false"
              name="Confirmação de Senha"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                label="Confirmação de Senha"
                type="password"
                v-model="confirmacaoSenha"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <vs-button
              :disabled="invalid"
              @click="refreshPassword()"
              class="w-full mt-8 uppercase"
              size="large"
              >Alterar senha</vs-button
            >
          </form>
        </ValidationObserver>
      </custom-card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CustomCard from "@/layouts/components/custom-card/CustomCard.vue";
import StaticText from "@/layouts/components/static-text/StaticText.vue";

export default {
  components: {
    CustomCard,
    StaticText
  },
  data() {
    return {
      tokenChangePasswordRequest: {},
      nome: "",
      senha: "",
      confirmacaoSenha: "",
      termosAceite: "",
      Newe: false
    };
  },
  methods: {
    ...mapActions("alteracao-senha", [
      "validarTokenDeAcesso",
      "atualizarSenha"
    ]),

    refreshPassword() {
      if (this.senha !== this.confirmacaoSenha) {
        return this.$onpoint.errorModal(
          "Senha e confirmação de senha estão diferentes."
        );
      }

      this.$onpoint.loadingModal("Processando sua solicitação", () => {
        return this.atualizarSenha({
          UserInfoUniqueId: this.tokenChangePasswordRequest.UserInfoUniqueId,
          UserInfoChangePasswordRequestUniqueId: this.tokenChangePasswordRequest
            .UniqueId,
          NewPassword: this.senha,
          ConfirmNewPassword: this.confirmacaoSenha
        })
          .then(() => {
            this.$onpoint.successModal(
              "Senha alterada com sucesso. Você será redirecionado para tela de login."
            );
            //const dns = response.data.Response.CompanyDns != undefined ? response.data.Response.CompanyDns.toLowerCase().trim() : ''
            setTimeout(() => {
              this.$router.push("/");
              // if (dns != 'localhost') {
              //   this.$router.push(`/login/${dns}`)
              // } else {
              //   this.$router.push('/')
              // }
            }, 2000);
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
            this.senha = "";
            this.confirmacaoSenha = "";
          });
      });
    }
  },
  beforeMount() {
    this.$onpoint.loadingModal("Processando sua solicitação", () => {
      return this.validarTokenDeAcesso(this.$route.params.token)
        .then(data => {
          this.tokenChangePasswordRequest = data.Response;
          this.nome = data.Response.DisplayName;
        })
        .catch(() => {
          this.nome = "Visitante";
          this.$onpoint.errorModal(
            "Não reconhecemos esse acesso. Vamos te direcionar para tela de login."
          );
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        });
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-alterar-senha",
      url: window.location.href
    });
    var Newe = parseInt(environment.CompanyId.Newe);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;
  }
};
</script>
<style scoped lang="scss">
.termos-modal {
  ::v-deep .vs-popup {
    width: 800px;
  }
  ::v-deep .vs-popup--header {
    background: white;

    .vs-popup--title {
      margin-top: 2em;
      text-align: center;
      color: var(--vs-primary);
    }
  }
}

h4 {
  color: inherit;
}

.justify-self-end {
  justify-self: end;
}
</style>
