var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2" },
    [
      _c("static-text"),
      _c(
        "div",
        { staticClass: "md:w-3/5 m-0 w-full justify-self-end" },
        [
          _c(
            "custom-card",
            { attrs: { title: "Alteração de Senha" } },
            [
              _c("h4", [_vm._v("Olá " + _vm._s(_vm.nome) + ", tudo bem?")]),
              _c(
                "p",
                {
                  staticStyle: {
                    "padding-top": "20px",
                    "padding-bottom": "20px",
                  },
                },
                [
                  _vm._v(
                    "\n        Preencha os campos abaixo e clique em atualizar senha.\n      "
                  ),
                ]
              ),
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ invalid }) {
                      return [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.refreshPassword()
                              },
                            },
                          },
                          [
                            _vm.Newe === true
                              ? _c("ValidationProvider", {
                                  attrs: {
                                    name: "Senha",
                                    rules: "required|min:8",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                label: "Senha",
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.senha,
                                                callback: function ($$v) {
                                                  _vm.senha = $$v
                                                },
                                                expression: "senha",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-danger text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _c("br"),
                            _vm.Newe === false
                              ? _c("ValidationProvider", {
                                  attrs: {
                                    name: "Senha",
                                    rules: "required|min:8",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                label: "Senha",
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.senha,
                                                callback: function ($$v) {
                                                  _vm.senha = $$v
                                                },
                                                expression: "senha",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-danger text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm.Newe === true
                              ? _c("ValidationProvider", {
                                  attrs: {
                                    name: "Confirmação de Senha",
                                    rules: "required|min:8",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                label: "Confirmação de Senha",
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.confirmacaoSenha,
                                                callback: function ($$v) {
                                                  _vm.confirmacaoSenha = $$v
                                                },
                                                expression: "confirmacaoSenha",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-danger text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _c("br"),
                            _vm.Newe === false
                              ? _c("ValidationProvider", {
                                  attrs: {
                                    name: "Confirmação de Senha",
                                    rules: "required|min:8",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c("vs-input", {
                                              staticClass: "w-full",
                                              attrs: {
                                                size: "large",
                                                label: "Confirmação de Senha",
                                                type: "password",
                                              },
                                              model: {
                                                value: _vm.confirmacaoSenha,
                                                callback: function ($$v) {
                                                  _vm.confirmacaoSenha = $$v
                                                },
                                                expression: "confirmacaoSenha",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: errors[0],
                                                    expression: "errors[0]",
                                                  },
                                                ],
                                                staticClass:
                                                  "text-danger text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(errors[0]) +
                                                    "\n            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full mt-8 uppercase",
                                attrs: { disabled: invalid, size: "large" },
                                on: {
                                  click: function ($event) {
                                    return _vm.refreshPassword()
                                  },
                                },
                              },
                              [_vm._v("Alterar senha")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }